// src/components/molecules/NavBar.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../atoms/Logo';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isSticky && <div style={{ height: '64px' }}></div>} {/* Adjust height to match your header height */}
      <header className={`bg-navy shadow-md transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 right-0 z-50 bg-opacity-90 backdrop-blur-sm' : ''
        }`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <Link to="/" className="flex-shrink-0">
              <Logo />
            </Link>
            <nav className="hidden md:flex space-x-8">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/footylab">footyLab</NavLink>
              <NavLink to="/contact">Contact Us</NavLink>
            </nav>
            <button
              className="md:hidden text-yellow focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <MobileNavLink to="/" onClick={() => setIsMenuOpen(false)}>Home</MobileNavLink>
              <MobileNavLink to="/about" onClick={() => setIsMenuOpen(false)}>About Us</MobileNavLink>
              <MobileNavLink to="/footylab" onClick={() => setIsMenuOpen(false)}>footyLab</MobileNavLink>
              <MobileNavLink to="/contact" onClick={() => setIsMenuOpen(false)}>Contact Us</MobileNavLink>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

const NavLink = ({ to, children }) => (
  <Link
    to={to}
    className="text-yellow hover:text-white transition-colors duration-300 ease-in-out"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="block px-3 py-2 text-yellow hover:text-white transition-colors duration-300 ease-in-out"
    onClick={onClick}
  >
    {children}
  </Link>
);

export default NavBar;