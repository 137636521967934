import React from 'react';

const FeaturedArticle = ({ title, excerpt, link }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-600 mb-4">{excerpt}</p>
        <a href={link} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
          Read Full Article
        </a>
      </div>
    </div>
  );
};

export default FeaturedArticle;