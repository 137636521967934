import React from 'react';
import Footer from '../organisms/Footer';
import NavBar from '../molecules/NavBar';

const PageLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <main className="flex-grow max-w-10xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default PageLayout;