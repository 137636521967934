import React from 'react';

const Logo = () => {
  return (
    <img 
      src="/dr_logo_new_vertical.png" 
      alt="DataRook Logo" 
      className="h-8 md:h-12 w-auto" // Adjust height as needed
    />
  );
};

export default Logo;