import React from 'react';
import Testimonial from '../molecules/Testimonial';

const TestimonialSection = () => {
  // Array of testimonial objects
  const testimonials = [
    {
      text: "Gustavo worked with our scholars at East TN Freedom Schools this past summer. He is knowledgeable about soccer & IT. He is thoughtful and easy to work with. He created a positive and engaging experience for our scholars. Gustavo cultivated positive working relationships with our staff and scholars. The project he built and offered worked so well we want to work with him again.",
      author: "Denise Dean",
      title: "Executive Director",
      organization: "East Tennessee Freedom Schools"
    },
    {
      text: "The camp introduced a new way of thinking about his performance on the field. There's been a shift from qualitative to quantitative. He now talks about metrics like speed and distance on top of goals and assists. Every day I picked him up he was smiling and was excited to tell me about the matches and how they played with the data. It also added some ownership of the data since he was not just being told what happened, but was a part of the process. Thanks One Knox for providing these types of opportunities!",
      author: "Teddy's Dad"
    },
    {
      text: "My daughter really enjoyed her Soccer/STEM experience!  It was fun and an appropriate skill level for a variety of ages and relating real world technologies to her favorite sport is so great, lets her know there are many different ways to have careers in sports!",
      author: "Evie's Mom"
    },
    {
      text: "My son and I went over his stats in great detail and how it relates to his gameplay. It has not only ignited his interest in leveraging the data to become a better player, but also has him asking about how this methodology can be used in other areas of his life such as his grades and how they relate to test score, homework, extra credit, etc. He truly has become fascinated with data and how it correlates with the world around him thanks to Gustavo.",
      author: "Garrett's Mom"
    },
    // Add Amanda Ingle's testimonial here when available
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">What People Are Saying About footyLab</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <Testimonial 
              key={index} 
              text={testimonial.text} 
              author={testimonial.author}
              title={testimonial.title}
              organization={testimonial.organization}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;