import React from 'react';

const Button = ({ children, onClick, className }) => {
  return (
    <button 
      className={`bg-yellow text-navy font-bold py-2 px-4 rounded hover:bg-navy hover:text-yellow transition-colors ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;