import React, { useEffect } from 'react';
import PageLayout from '../components/templates/PageLayout';
import Button from '../components/atoms/Button';

const Contact = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8 text-center">Contact Us</h1>
        
        <div className="max-w-4xl mx-auto">
          <p className="text-xl mb-8 text-center">
            Have questions about footyLab or want to bring our innovative STEM program to your community? We're excited to hear from you! Whether you're a parent, educator, or potential partner, let's start a conversation that could change the future of learning.
          </p>

          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
            <p className="mb-4">
              The best way to reach us is through the form below. If you prefer, you can also email us directly at:{' '}
              <a href="mailto:info@datarook.com" className="text-blue-600 hover:underline">info@datarook.com</a>
            </p>
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Connect With Us</h3>
              <div className="flex space-x-4">
                <a href="https://www.linkedin.com/company/datarook" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                  <i className="fab fa-linkedin"></i> LinkedIn
                </a>
                <a href="https://www.youtube.com/@datarook" target="_blank" rel="noopener noreferrer" className="text-red-600 hover:text-red-800">
                  <i className="fab fa-youtube"></i> YouTube
                </a>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Send Us a Message</h2>
            <iframe
              src="https://tally.so/embed/wQ0jjg?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              width="100%"
              height="500"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Contact Form"
            ></iframe>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl font-semibold">How can I bring footyLab to my school or community?</h3>
                <p>Contact us using the form above or send an email to info@datarook.com. We'll be happy to discuss how we can tailor our program to your needs.</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold">What age group is footyLab designed for?</h3>
                <p>footyLab is designed for youth aged 10-18, but we can adapt our curriculum for different age groups. Let us know your specific needs!</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold">Do participants need prior coding experience?</h3>
                <p>No prior coding experience is necessary. Our curriculum is designed to accommodate beginners while also challenging more experienced students.</p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Ready to Get Started?</h2>
            <Button onClick={() => document.querySelector('iframe').scrollIntoView({ behavior: 'smooth' })}>
              Contact Us Now
            </Button>
          </div>

          <p className="mt-8 text-sm text-gray-600 text-center">
            Your privacy is important to us. We'll only use your information to respond to your inquiry and won't share it with third parties.
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Contact;
