import React, { useState, useEffect } from 'react';
import PageLayout from '../components/templates/PageLayout';
import Button from '../components/atoms/Button';
import LazyImage from '../components/atoms/LazyImage';

const FootyLab = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    // Change favicon for FootyLab page
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = "/footylab_v2_icon.png";
    }
    return () => {
      // Reset favicon when leaving the page
      if (link) {
        link.href = "/dr_icon.png";
      }
    };
  }, []);

  return (
    <PageLayout>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-8 text-center">footyLab: Where Soccer Meets Data Science</h1>
        <div className="mb-12 text-center">
          <LazyImage
            src="/footylab_v2_vertical.png"
            alt="footyLab Logo"
            className="mx-auto mb-8 w-auto"
            style={{maxWidth: '360px', maxHeight: '200px'}}
          />
          <p className="text-xl mb-6">
            footyLab is a groundbreaking program that combines the excitement of soccer with cutting-edge data science education. Using professional-grade analytics tools, students aged 10-18 learn to analyze their own performance data, developing crucial STEM skills along the way.
          </p>
          <Button 
            onClick={toggleModal} 
            className="text-xl font-bold py-3 px-6"
          >
            Program Cost: $10/hour/student
          </Button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
              <h2 className="text-2xl font-bold mb-4">Estimate Your Cost</h2>
              <p className="mb-4">Below are typical implementations and estimated costs:</p>
              <ul className="list-disc list-inside">
                <li>One-off session (2 hours) for 16 students: $320</li>
                <li>6-week course (2 hours per session), once a week for 16 students: $1,920</li>
                <li>6-week course (2 hours per session), twice a week for 16 students: $3,840</li>
                <li>Semester (12 weeks, 2 sessions per week, 2 hours each) for 16 students: $7,680</li>
              </ul>
              <p className="mt-4 text-sm text-gray-700">
                Note: Our group sizes range from 10-20 students.
              </p>
              <h3 className="text-xl font-bold mt-6 mb-2">Perfect for Homeschooling Co-ops!</h3>
              <p className="text-gray-700">
                footyLab offers a unique blend of physical education and STEM learning, making it an ideal and holistic educational experience for homeschooling groups. With flexible session scheduling and a curriculum designed to integrate seamlessly into various learning environments, homeschooling co-ops can benefit from both top-notch educational content and socially engaging sports activities.
                This hands-on, practical approach helps develop critical skills while building teamwork and community among students.
              </p>
              <Button onClick={toggleModal} className="mt-6">
                Close
              </Button>
            </div>
          </div>
        )}

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Program Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">What You'll Learn</h3>
              <ul className="list-disc list-inside">
                <li>Python programming basics</li>
                <li>Data analysis and visualization</li>
                <li>Statistical concepts and their applications</li>
                <li>Soccer performance analytics</li>
              </ul>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4">What You'll Do</h3>
              <ul className="list-disc list-inside">
                <li>Play soccer using professional tracking technology</li>
                <li>Analyze your own performance data</li>
                <li>Develop critical thinking and problem-solving skills by building a webapp</li>
                <li>Create data-driven strategies to improve your game</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">See It in Action</h2>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.youtube.com/embed/wBY0Qlk_djU?si=x0LJdv_Kwz0kKLVa"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Our Technology</h2>
          <p className="mb-4">footyLab utilizes professional-grade equipment to provide a true sports analytics experience:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-4">Veo Cameras</h3>
              <p>AI-powered cameras that capture 180° footage of the entire field, allowing for comprehensive video analysis.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4">Catapult Trackers</h3>
              <p>Wearable GPS devices that collect detailed performance data, including distance covered, speed, and acceleration.</p>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Our Instructors</h2>
          <p className="mb-4">footyLab instructors bring a unique blend of expertise in both soccer and data science:</p>
          <ul className="list-disc list-inside">
            <li>Experienced youth soccer coaches</li>
            <li>Data scientists with backgrounds in sports analytics</li>
            <li>Educators passionate about making STEM accessible and engaging</li>
          </ul>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">Who can participate in footyLab?</h3>
              <p>footyLab is designed for youth soccer players aged 10-18, regardless of their current skill level in soccer or data science.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Do I need to bring my own equipment?</h3>
              <p>No, all necessary technology and equipment will be provided during the sessions.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">How long is the program?</h3>
              <p>The program typically runs in 6-week sessions, with classes held once or twice a week.</p>
            </div>
          </div>
        </section>

        <div className="text-center">
          <Button onClick={() => window.location.href='/contact'} className="text-xl py-3 px-6">
            Enroll in footyLab
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default FootyLab;