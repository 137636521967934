import React from 'react';

const Testimonial = ({ text, author, title, organization }) => (
  <div className="bg-white shadow-lg rounded-lg p-6">
    <p className="text-gray-600 italic mb-4">"{text}"</p>
    <p className="font-bold">{author}</p>
    {(title || organization) && (
      <p className="text-sm text-gray-500">
        {title && `${title}${organization ? ', ' : ''}`}
        {organization}
      </p>
    )}
  </div>
);

export default Testimonial;